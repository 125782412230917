
html.color-header .topbar .navbar-nav .nav-link {

	color: #ffffff;

}

html.color-header .search-bar input {

	color: #413f3f;

	background-color: #ffffff !important;

	;

	border: 1px solid rgb(241 241 241 / 15%) !important;

	box-shadow: inset 0 1px 2px rgb(0 0 0 / 0%);

}

html.color-header .search-bar input:focus {

	box-shadow: 0 0 0 .25rem rgba(255, 255, 255, 0.25)

}

html.color-header .search-bar input::placeholder {

	color: #08090a !important;

	opacity: .5 !important;

	/* Firefox */

}

html.color-header::placeholder {

	color: #08090a !important;

	opacity: .5 !important;

	/* Firefox */

}

html.color-header .search-show {

	color: #221f1f;

}

html.color-header .user-info .user-name {

	color: #ffffff;

}

html.color-header .user-info .designattion {

	color: #ffffff;

}

html.color-header .user-box {

	border-left: 1px solid rgb(255 255 255 / 0.35) !important;

}

html.color-header .topbar-logo-header {

	border-right: 1px solid rgb(255 255 255 / 0.35) !important;

}

html.color-header .topbar-logo-header .logo-icon {

	filter: invert(1) grayscale(100%) brightness(200%);

}


html.color-header .topbar-logo-header .logo-text{
	color: #ffffff;
}

html.color-header .mobile-toggle-menu {

	color: #ffffff;

}

/* header colors */

.switcher-body .headercolor1 {

	background: #0727d7 !important;

}

.switcher-body .headercolor2 {

	background: #23282c !important;

}

.switcher-body .headercolor3 {

	background: #e10a1f !important;

}

.switcher-body .headercolor4 {

	background: #157d4c !important;

}

.switcher-body .headercolor5 {

	background: #673ab7 !important;

}

.switcher-body .headercolor6 {

	background: #795548 !important;

}

.switcher-body .headercolor7 {

	background: #d3094e !important;

}

.switcher-body .headercolor8 {

	background: #ff9800 !important;

}

html.headercolor1 .topbar {

	background: #0727d7;

}

html.headercolor2 .topbar {

	background: #23282c;

}

html.headercolor3 .topbar {

	background: #e10a1f;

}

html.headercolor4 .topbar {

	background: #157d4c;

}

html.headercolor5 .topbar {

	background: #673ab7;

}

html.headercolor6 .topbar {

	background: #795548;

}

html.headercolor7 .topbar {

	background: #d3094e;

}

html.headercolor8 .topbar {

	background: #ff9800;

}


