.category-table tr td{
   padding: 15px;
}

.category-table th{
    padding: 15px;
 }

 
.category-table {
    vertical-align: middle !important;
    padding: 20px;
}


.form-login .form-control {
    border: 1px solid #aaaaaa;
    padding: 15px;
}


.form-login .form-control:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: #008cff;
}


.btn:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: #008cff;
}

.bg-none{
    background-color: transparent !important;
    color: black;
    border: none;
}
.img.img-fluid {
    width: 100% !important;
    object-fit: cover;
}